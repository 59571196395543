import React from "react";
import Banner from "./smallParts/banner";
import Company from "./smallParts/company";
import "../css/sertificate.css";

import img1 from '../img/sertifikat/serf1.jpg'
import img2 from '../img/sertifikat/serf2.jpg'
import img3 from '../img/sertifikat/serf3.jpg'

function Sertificate() {
  return (
    <div>
      <Banner title="Certificate" />
      <div className="serf container">
        <img src={img1} alt="" />
        <img src={img2} alt="" className="center"/>
        <img src={img1} alt="" /> 
      </div>
      <Company />
    </div>
  );
}

export default Sertificate;
