// About.js
import React from "react";
import { Link } from "react-router-dom";
import "../css/about.css";
import Company from "./smallParts/company";
import Banner from "./smallParts/banner";
import CustomSlider from "./smallParts/customSlider";
import Banner1 from "../img/banner/imlionpn.png";

import img1 from "../img/women/img1.png";
import img2 from "../img/women/img2.png";
import img3 from "../img/women/img3.png";

function About() { 
  const slides = [
    {
      title: "Quality control",
      description: "Constant quality control and the presence of foreign experienced specialists and the availability of modern technologies from leading manufacturers of the world ensure the production of high-quality products as well as the competitiveness of the products of Luxury Gold Tex on the knitwear market",
      rating: 4,
      imageUrl: img1,
    },
    {
      title: "Production",
      description: "The annual volume of production on average in recent years is 6 million pieces per year  At the request of our customers, a culinary surface (with and without lycra), pique (lacoste),  interlock, ribana, koshkorse, 2 and 3 thread footer, collars and cuffs, cords and extrafores, rubbers  (elastane), etc. are produced. ",
      rating: 4,
      imageUrl: img2,
    },
    {
      title: "Production capacity",
      description: "T-shirts (men's and women's) - 3 million pieces per year Polo shirts (men's and women's) - 0.5 million pieces per year Hoodies, trousers and underwear - 0.5 million pieces per year Sports kits - 0.5 million pieces per year Robes, tunics, shirts, straps - 1.5 million per year",
      rating: 5,
      imageUrl: img3,
    },
  ];

  return (
    <div>
      <Banner title="About Us" />
      <div className="about-content-1 container">
        <h1>
          About <span className="mark">Us</span>
        </h1>
        <div className="about-cont">
          <div className="about-left">
            <img src={Banner1} alt="" />
          </div>
          <div className="about-right">
            <h3>Additional information about organization and they activity</h3>
            <p>
              Our company LLC "Luxury Gold Tex" offers a wide selection of
              textile products for the whole family.T-shirts, T-shirts, shirts,
              shorts, sportswear, bathrobes and much more. LLC "Luxury Gold Tex"
              has been successfully developing for more than 5 years
              specializing in sewing ready-made women's, men's and children's
              knitwear. We produce all manufactured products under the brand
              "imlion" Our production facilities allow us to fulfill the most
              complex orders in any volume. Having 2 production factories, the
              company has the capacity to produce clothes
            </p>
            <button className="btn"><Link to="/contact">Contact Us</Link></button>
          </div>
        </div>
      </div>
      <CustomSlider slides={slides} />
      <Company />
    </div>
  ); 
}

export default About;
