// components/Nav.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTelegram, faInstagram, faFacebook, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Logo from "../img/testimonials/Imlionpng2.png";
import Logo2 from '../img/testimonials/Imlionpng.png';
import "../css/nav.css";

const Nav = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`${isFixed ? "fixed" : ""}`}>
      <div className='container nav'>
        <img className='logo' src={Logo} alt="Logo" />
        <img className='logo2' src={Logo2} alt="Logo 2" />
        <ul className={`nav-list ${isMobileMenuOpen ? "show" : ""}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/certificate">Certificate</Link></li>
          <li><Link to="/catalog">Catalog</Link></li>
          <li className="dropdown">
            <span className="dropdown-toggle">Blog</span>
            <ul className="dropdown-menu">
              <li><a href="https://t.me/imliontextile"><FontAwesomeIcon icon={faTelegram} /> Telegram</a></li>
              <li><a href="https://www.instagram.com/imlion_uz/"><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li>
              <li><a href="https://www.facebook.com/ziyodillo.burkhanov"><FontAwesomeIcon icon={faFacebook} /> Facebook</a></li>
              <li><a href="https://www.youtube.com/@abduraximburxonv5502"><FontAwesomeIcon icon={faYoutube} /> Youtube</a></li>
              <li><a href="https://whatsapp.com"><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp</a></li>
            </ul>
          </li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
        <div className="menu-icon" onClick={toggleMobileMenu}>
        <i class="fa-solid fa-bars"></i>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
