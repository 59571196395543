// components/Banner.js
import React from "react";
import "../../css/banner.css";

const Banner = ({ title }) => {
  return (
    <div className="banner-container">
      <div className="banner-content ">
        <h1>{title}</h1>
      </div>
    </div> 
  );
};

export default Banner;
