import React from 'react'
import comp1 from '../../img/brands/download.png'
import comp2 from '../../img/brands/Svetofor-logo.png'
import comp3 from '../../img/brands/t-rone.png'
import comp4 from '../../img/brands/mf.png'
import comp5 from '../../img/brands/huma.png'
import "../../css/company.css";


function company() {
  return ( 
    <div className='company container'>
        <img src={comp1} alt="" />
        <img src={comp2} alt="" />
        <img src={comp3} alt="" />
        <img src={comp4} alt="" />
        <img src={comp5} alt="" />
    </div>
  )
}

export default company