import React, { useState } from "react";
import "../css/catalog.css";
import Company from "./smallParts/company";
import Banner from "./smallParts/banner";

import women1 from "../img/women/g1.jpg";
import women2 from "../img/women/g2.jpg";
import women3 from "../img/women/g3.jpg";
import women4 from "../img/women/g4.jpg";
import women5 from "../img/women/g5.JPG";
import women6 from "../img/women/g6.JPG";
import women7 from "../img/women/g7.JPG";
import women8 from "../img/women/g8.JPG";
import men1 from "../img/women/men/men1.jpg";
import men2 from "../img/women/men/men2.jpg";
import men3 from "../img/women/men/men3.jpg";
import men4 from "../img/women/men/men4.jpg";
import men5 from "../img/women/men/men5.jpg";
import men6 from "../img/women/men/men6.jpg";
import men7 from "../img/women/men/men7.jpg";
import men8 from "../img/women/men/men8.jpg";

const Catalog = () => {
  const categories = ["All", "Male", "Female"];
  const filters = ["All", "Popular", "Latest", "Following", "Upcoming"];

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedFilter, setSelectedFilter] = useState("All");

  const catalogData = [
    { id: 1, categories: ["all", "female", "popular", 'upcoming'], imageUrl: women1 },
    { id: 2, categories: ["all", "female", 'following', 'upcoming'], imageUrl: women2 },
    { id: 3, categories: ["all", "latest", "female", "popular"], imageUrl: women3 },
    { id: 4, categories: ["all", "latest", "female",'following'], imageUrl: women4 },
    { id: 5, categories: ["all", "popular", "female", 'upcoming'], imageUrl: women5 },
    { id: 6, categories: ["all", "female",'following'], imageUrl: women6 },
    { id: 7, categories: ["all", "latest", "female","popular", 'upcoming'], imageUrl: women7 },
    { id: 8, categories: ["all", "latest", "female", "latest",'following'], imageUrl: women8 },
    { id: 9, categories: ["all", "popular", "male",'following'], imageUrl: men1 },
    { id: 10, categories: ["all", "male", 'upcoming', "popular"], imageUrl: men2 },
    { id: 11, categories: ["all", "latest", "male" ,"popular"], imageUrl: men3 },
    { id: 12, categories: ["all", "latest", "male",'following'], imageUrl: men4 },
    { id: 13, categories: ["all", "popular", "male", 'upcoming'], imageUrl: men5 },
    { id: 14, categories: ["all", "male", 'upcoming','following'], imageUrl: men6 },
    { id: 15, categories: ["all", "latest", "male", 'upcoming'], imageUrl: men7 },
    { id: 16, categories: ["all", "latest", "male",'following'], imageUrl: men8 },
    // Add more images for men with different categories
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedFilter("All");
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredCatalogData = catalogData.filter((item) => {
    if (selectedCategory === "All" && selectedFilter === "All") return true;
    if (selectedCategory !== "All" && selectedFilter === "All") {
      return item.categories.includes(selectedCategory.toLowerCase());
    }
    if (selectedCategory === "All" && selectedFilter !== "All") {
      return item.categories.includes(selectedFilter.toLowerCase());
    }
    return (
      item.categories.includes(selectedCategory.toLowerCase()) &&
      item.categories.includes(selectedFilter.toLowerCase())
    );
  }); 

  return (
    <div>
      <Banner title="Catalog" />
        <div className="catalog-container container">
          <h1 className="title">Catalog</h1>
          <h3 className="title">Check Our Recent Client Work</h3>
        <div className="category-filter">
        <ul className="category-list">
          {categories.map((category) => (
            <li
              key={category}
              className={selectedCategory === category ? "active" : ""}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </li>
          ))}
        </ul>
        {selectedCategory !== "All" && (
          <ul className="filter-list">
            {filters.map((filter) => (
              <li
                key={filter}
                className={selectedFilter === filter ? "active" : ""}
                onClick={() => handleFilterClick(filter)}
              >
                {filter}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="catalog-images">
        {filteredCatalogData.map((item) => (
          <div key={item.id} className="catalog-item">
            <img src={item.imageUrl} alt={`Image ${item.id}`} />
          </div>
        ))}
      </div>

        </div>
      <Company />
    </div>
  );
};

export default Catalog;
