import Banner from "./smallParts/banner";
import React from "react";
import "../css/contact.css";

const Contact = () => {
  return (
    <div>
      <Banner title="Contact" />
      <div className="contact-content container">
        <h1>
          Contact <span className="mark title">Us</span>
        </h1>
        <form>
          <div className="form-f">
            <div>
              <input type="text" name="name" placeholder="Name" />
            </div>
            <div>
              <input type="email" name="email" placeholder="Email" />
            </div>
          </div>
          <div>
            <input type="text" name="subject" placeholder="Subject" />
          </div>
          <div>
            <textarea name="message" placeholder="Message" />
          </div>
          <button type="submit" className="btn title">
            Submit
          </button>
        </form>
      </div>
      <div className="adress container">
        <div className="adress-box">
          <span>
            <i class="fa-solid fa-house"></i>
          </span>
          <h4>Address:</h4>
          <h5 className="title">Uzbekistan, Andijan, Khodzhaabad</h5>
          <p>village Khidersha, Obod Turmush Street, 3</p>
        </div>
        <div className="adress-box">
          <span>
            <i class="fa-solid fa-phone"></i>
          </span>
          <h4>Phone Number:</h4>
          <div className="f-box">
            <h5>+998(98) 000 22 01</h5>
            <h5>+998(98) 800 22 01</h5>
          </div>
          <p>Mon to Sat 7:30am to 6pm</p>
        </div>
        <div className="adress-box">
          <span>
            <i class="fa-solid fa-envelope"></i>
          </span>
          <h4>E-mail:</h4>
          <div className="f-box">
            <h5>luxrygoldtex@inbox.ru</h5>
            <h5>investdavrtex@inbox.ru</h5>
          </div>
          <p>Send us your query anytime!</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
 