import React from "react";
import Showcase from "./smallParts/Showcase";
import Textile from "./smallParts/Textile";
import HAbout from "./smallParts/HAbout";
import Services from "./smallParts/Services";
import HCatalog from "./smallParts/HCatalog";
import Company from "./smallParts/company";

const Home = () => {
  return (
    <div>
      <Showcase />
      <Textile />
      <HAbout />
      <Services />
      <HCatalog />
       <Company />
    </div>
  );
};

export default Home;
