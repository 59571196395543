import React from "react";
import "../../css/textile.css";


function Textile() {
  return (
    <div className="container textile"> 
      <h1>
        Imlion <span className="mark">Textile</span>
      </h1>
      <div className="textile-content">
        <div className="textile-left">
          <h3>Imlion textile cutting department</h3>
          <p>
            The size of the cutting section may vary depending on the scale of
            production in the textile factory.Our factory cutting department has
            several cutting tables and computerized cutting machines,while
            smaller factories may have more of a manual cutting
            process.Efficiency and Processes: The efficiency of the cutting
            department depends on the workflow,organization, and management
            practices. A well-organized department can help optimize fabric
            utilization and reduce production lead times. It's important to note
            that the classification of the cutting department can vary from one
            textile factory to another, based on the factory's specific
            production processes, machinery, and organizational structure.For
            accurate information about the classification of Imlion Textile
            Factory's cutting department,you would need to refer to their
            official documentation or contact the company directly for the most
            up-to-date information.
          </p>
          <button className="btn"><a href="https://www.youtube.com/@abduraximburxonv5502">watch me</a></button>
        </div>
        <div className="textile-right">
        <iframe width="400" height="415" src="https://www.youtube.com/embed/fVggKHnbP8M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
      <div className="customer">
        <div className="cust-box">
          <h1 className="mark">10k+</h1>
          <p>Happy Customer</p>
        </div>
        <div className="cust-box">
          <h1 className="mark">8k+</h1>
          <p>Ticket Solved</p>
        </div>
        <div className="cust-box">
          <h1 className="mark">9/10</h1>
          <p>Average Rating</p>
        </div>
      </div>
    </div>
  );
}

export default Textile;
