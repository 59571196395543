// components/Footer.js
import React from "react";
import "../css/footer.css";
import logo from "../img/testimonials/Imlionpng2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content container">
        <div className="footer-top">
          <div className="footer-logo">
            <img src={logo} alt="" />
          </div>
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/sertificate">Sertificate</Link></li>
            <li><Link to="/catalog">Catalog</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
          <div className="footer-icon">
            <span>
              <a href="https://www.instagram.com/imlion_uz/">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </span>
            <span> 
              <a href="https://www.facebook.com/ziyodillo.burkhanov">
                <i className="fa-brands fa-facebook"></i>
              </a>
            </span>
            <span>
              <a href="https://www.youtube.com/@abduraximburxonv5502">
                <i className="fa-brands fa-youtube"></i>
              </a>
            </span>
            <span>
              <a href="https://t.me/imliontextile">
                <i className="fa-brands fa-telegram"></i>
              </a>
            </span>
            <span>
              <a href="#">
                <i className="fa-brands fa-whatsapp"></i>
              </a>
            </span>
          </div>
        </div>
        <p className="copy">&copy; 2023 Your Sewing Factory. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
