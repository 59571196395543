// components/CustomSlider.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "../../css/customSlider.css";

const CustomSlider = ({ slides }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className="custom-slider-container container">
      <div
        className="custom-slider"
        style={{ transform: `translateX(-${activeSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === activeSlide ? "active" : ""}`}
          >
            <div className="slide-image">
              <img src={slide.imageUrl} alt={`Slide ${index + 1}`} />
            </div>
            <div className="slide-content">
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
              <div className="rating">
                {[...Array(slide.rating)].map((_, i) => (
                  <FontAwesomeIcon key={i} icon={faStar} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="slider-dots">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === activeSlide ? "active" : ""}`}
            onClick={() => setActiveSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomSlider;
 