import React from "react";
import "../../css/services.css";
import { Link } from "react-router-dom";

function services() {
  return (
    <div className="container services">
      <h1>
        <span className="mark">Our</span>Service
      </h1> 
      <h3>What Service We Offer For You</h3>
      <div className="services-content">
        <div className="serv-box">
          <h4>Contact US</h4>
          <span className="icon"> 
            <i class="fa-solid fa-phone"></i>
          </span>
          <p>
            Contact us if you like our products! Learn more about our products
            and contact us to place an order.
          </p>
          <button>
            <Link to="/contact">Contact</Link>
          </button>
        </div>
        <div className="serv-box">
          <h4>Shop</h4>
          <span className="icon"> 
            <i class="fa-solid fa-cart-shopping"></i>
          </span>
          <p>
            If you like our products, you can buy and place an order. You can
            see our products in the <span className="mark">Catalog</span> section.
          </p>
          <button>
            <Link to="/catalog">Catalog</Link>
          </button>
        </div>
        <div className="serv-box">
          <h4>Delivery</h4>
          <span className="icon"> 
          <i class="fa-solid fa-truck"></i>
          </span>
          <p>
            Our factory has a service of delivery of our products purchased
            online throughout <span className="mark">Uzbekistan</span>
          </p>
          <button>
            <Link to="/contact">Contact</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default services;
