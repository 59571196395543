// components/Directory.js
import React, { useState } from "react";
import img1 from "../../img/women/g1.jpg";
import img2 from "../../img/women/men/men1.jpg";
import img3 from "../../img/women/g2.jpg";
import img4 from "../../img/women/men/men2.jpg";
import img5 from "../../img/women/g3.jpg";
import img6 from "../../img/women/men/men3.jpg";
import img7 from "../../img/women/g4.jpg";
import img8 from "../../img/women/men/men4.jpg";
import "../../css/HCatalog.css"; 

const Directory = () => {
  const [filter, setFilter] = useState("all");

  const data = [
    { id: 1, category: ["all", "following", "upcoming"], imageUrl: img1 },
    { id: 2, category: ["all", "following", "upcoming"], imageUrl: img2 },
    { id: 3, category: ["all", "latest", "following"], imageUrl: img3 },
    {
      id: 4,
      category: ["all", "popular", "latest", "following"],
      imageUrl: img4,
    },
    { id: 5, category: ["all", "upcoming", "latest"], imageUrl: img5 },
    {
      id: 6,
      category: ["all", "popular", "following", "upcoming"],
      imageUrl: img6,
    },
    {
      id: 7,
      category: ["all", "popular", "latest", "upcoming"],
      imageUrl: img7,
    },
    { id: 8, category: ["all", "popular", "following"], imageUrl: img8 },
    // Add more data for other images with different categories
  ];

  const handleFilterChange = (filter) => {
    setFilter(filter);
  };

  return (
    <div className="hcatalog container">
        <h1><span className="mark">Our</span> Catalog</h1>
        <h3>Check Our Recent</h3>
      <div className="directory-container ">
        {/* Filter list */}
        <ul className="filter-list">
          <li
            onClick={() => handleFilterChange("all")}
            className={filter === "all" ? "active" : ""}
          >
            All
          </li>
          <li
            onClick={() => handleFilterChange("popular")}
            className={filter === "popular" ? "active" : ""}
          >
            Popular
          </li>
          <li
            onClick={() => handleFilterChange("latest")}
            className={filter === "latest" ? "active" : ""}
          >
            Latest
          </li>
          <li
            onClick={() => handleFilterChange("following")}
            className={filter === "following" ? "active" : ""}
          >
            Following
          </li>
          <li
            onClick={() => handleFilterChange("upcoming")}
            className={filter === "upcoming" ? "active" : ""}
          >
            Upcoming
          </li>
        </ul>

        {/* Images */}
        <div className="image-container">
          {data.map((item) => {
            if (filter === "all" || item.category.includes(filter)) {
              return (
                <div key={item.id} className="image-item">
                  <img src={item.imageUrl} alt={`Image ${item.id}`} />
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Directory;
