import React, { useState, useEffect } from "react";
import images1 from "../../img/banner/showcase1.png";
import images2 from "../../img/banner/showcase2.png";
import bg from '../../img/testimonials/Imlionpng.png'
import "../../css/showcase.css";

const Showcase = () => {
  const localData = [
    {
      id: 1,
      title: "WELCOME TO Imlion Textile",
      imageUrl: images1,
    },
    {
      id: 2,
      title: "WELCOME TO Imlion Textile",
      imageUrl: images2,
    },
    // Add more local data as needed
  ];

  const [showcaseData, setShowcaseData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setShowcaseData(localData);
  }, []);

  useEffect(() => {
    // Auto-scroll the carousel
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % showcaseData.length);
    }, 4000); 

    return () => clearInterval(interval);
  }, [showcaseData]);
  return (
    <div className="carousell">
      <div className="carousel-container container">
        {showcaseData.map((item, index) => (
          <div
            key={item.id}
            className={`carousel-item ${index === activeIndex ? "active" : ""}`}
          >
            <div className="cont-show">
              <div className="carousel-item-content">
              <h3>{item.title}</h3>
              <img src={bg} alt="" />
              </div>
              <div className="cont-img">
              <img className="img" src={item.imageUrl} alt={item.title} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Showcase;
