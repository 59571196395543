import React from "react";
import "../../css/HAbout.css";
import about from "../../img/banner/about.jpg";
import { Link } from 'react-router-dom';


function homeAbout() {
  return (
    <div className="HAbout container">
      <h1> 
        About <span className="mark">Us</span>
      </h1>
      <div className="HAbout-content">
        <img src={about} className="HAbout-left" />
        <div className="HAbout-right">
          <h2>Revamp your style with Imlion Textile</h2>
          <p>
            Garment factory, children's t-shirts, clothes for the whole
            family,children's t-shirts, clothes for the whole family, Production
            and export of textile products from Uzbekistan,Manufacture and
            export of textiles, Manufacture and export of knitwear, Wholesale of
            knitwear,Knitting factory , Women's robes, Women's tunics, Men's
            T-shirt, Women's T-shirt, Men's longsleeve, Women's longsleeve,
            Children's longsleeve, Men's sweatshirt, Women's sweatshirt,
            Children's sweatshirt,Men's tracksuit, Women's tracksuit, Children's
            sports costume, Tops, straps for women, Men's trousers,Children's
            trousers Women's trousers
          </p>
          <button className="btn"><Link to="/about">More info</Link></button>
        </div>
      </div>
    </div>
  );
}

export default homeAbout;
